@keyframes shake {
  0%, 100% { transform: rotate(0deg); }
  10% { transform: rotate(35deg); }
  20% { transform: rotate(-35deg); }
  30% { transform: rotate(35deg); }
  40% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }

}

.shake {
  animation: shake 1s infinite;
}
