:root {
  --bottom-copyright-height: 36px;
}

*::-webkit-scrollbar, ul::-webkit-scrollbar {
  width: 16px;
  height: 16px;
  background-color: transparent;
  /* background-color: #EFEFEF;
  border-radius: 8px; */
}
 
/* *::-webkit-scrollbar-track,  ul::-webkit-scrollbar-track {
}
  */
*::-webkit-scrollbar-thumb, ul::-webkit-scrollbar-thumb {
  background-color: #D8D8D8;
  border-radius: 8px;
  border: 2px solid transparent;
  background-clip: content-box;
}

*::-webkit-scrollbar-corner, ul::-webkit-scrollbar-corner {
  background-color: transparent;
}

body {
  font-family: 'Inter', 'sans serif';
}

#root {
  height: 100%;
  background-color: inherit;
}

.threeD-highchart-container {
  width: 100%;
  height: 100%;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

::placeholder {
  font-size: 16px;
}

.MuiDataGrid-cell--gray {
  background-color: #B5C0D0;
}

.MuiDataGrid-cell.data-grid-cell-validation-error {
  border-color: #D92D20 !important;
  border-width: '1px';
  border-style: solid;
}

