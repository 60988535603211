.slate-custom-table {
  width:100%;
  /*width: 600px;*/
  border-collapse: collapse;
}

.slate-custom-table td {
  border: 1px solid black;
  padding: 16px;
}

/*td{*/
/*    height: 50px;*/
/*    padding:0 5px;*/
/*}*/
.popup-wrapper{
    display: inline;
    position: relative;
}
.popup{
    position: absolute;
    left: 0;
    background-color: white;
    padding: 6px 10px;
    border: 1px solid lightgray;
    height: fit-content;
    z-index: 1;

}

.slate-custom-alignCenter {
    text-align: center;
}

.slate-custom-alignRight {
    text-align: right;
}
